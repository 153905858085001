import request from '_utils/request'

const get_download_url = (params) => {
  return request({
    url: '/app/down/selectDownUrl',
    method: 'GET',
    params
  })
}

const get_ios_download_url = (params) => {
  return request({
    url: '/api/system/config/getValue/IOS_DOWNLOAD_APP_URL',
    method: 'GET',
    params
  })
}

export {
  get_download_url,
  get_ios_download_url
}